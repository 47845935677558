<template>
  <div class="tree">
    <div class="content-box"
         :class="{'content-box-select':selectTitle&&selectTitle === item.id}"
         v-for="(item,index) of data"
         :key="index">
      <div class="title-box flex_row br_5 pointer"
           :class="{'title-select':selectOption&&selectOption === item.id}"
           ref="title"
           @click="title_click(item,index)">
        <div class="title fs_14 p_in_10 text_hidden">{{ item.label }}</div>
        <i class="allow el-icon-caret-right"
           :class="{'allow-rotate': selectTitle&&selectTitle === item.id}"
           v-if="item.children.length!==0"></i>
      </div>
      <TreeItem class="tree-content"
                v-if="item.children"
                :data="item.children"
                :selectOption="selectOption"
                @xxx="selectOption_click"
      />
    </div>
  </div>
</template>

<script>
import TreeItem from './TreeItem'

export default {
  name: 'TreeItem',
  components: {
    TreeItem
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    selectTitle: {
      type: String,
      default: null
    },
    selectOption: {
      type: String,
      deflate: null
    }
  },
  data: () => {
    return {}
  },
  methods: {
    title_click(content) {
      if (content.children.length !== 0) {
        this.$emit('selectTitle', content.id)
      } else {
        this.$emit('xxx', content.id)
      }
    },
    selectOption_click(id) {
      this.$emit('selectOption', id)
    }
  }
}
</script>

<style scoped>
.content-box {
  max-height: 40px;
  overflow: hidden;
}

.content-box-select {
  max-height: 1000px;
  justify-content: space-between;
  transition: max-height 2s linear;
}

.title-box {
  height: 40px;
  justify-content: space-between;
}

.title-box:hover {
  color: #12C294;
}

.title-select {
  color: #12C294;
  background-color: #dcf6ef;
}

.allow {
  line-height: 40px;
  color: #cccccc;
  transition: transform .1s linear;
}

.allow-rotate {
  transform: rotate(90deg);
  transition: transform .2s linear;
}

.tree-content {
  margin-left: 10px;
}
</style>
