<template>
  <TreeItem :data="data"
            :selectTitle="selectTitle"
            :selectOption="selectOption"
            @selectTitle="selectTitle_click"
            @selectOption="selectOption_click"/>
</template>

<script>
import TreeItem from './TreeItem'

export default {
  name: 'Tree',
  components: {
    TreeItem
  },
  props: {
    data: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => {
    return {
      selectTitle: null,
      selectOption: null
    }
  },
  methods: {
    selectTitle_click(id) {
      this.selectTitle = id
    },
    selectOption_click(id) {
      this.selectOption = id
      this.$emit('selectLeftOption', id)
    }
  }
}
</script>

<style scoped>
</style>
