<template>
  <div
    class="box"
    v-loading.fullscreen.lock="fullscreenLoading">
    <img
      :src="banner"
      alt="省级资源"
      style="margin-top: 20px"
      width="100%" />
    <!--  资源板块  -->
    <div class="plate-box flex_row m_tb_20">
      <div
        class="plate br_10 fs_18 bg_fff p_in_10 pointer flex_row"
        :class="{ 'select-classify': item.id === selectedClassify }"
        v-for="(item, index) of classifyList"
        :key="index"
        @click="classify_click(item.id)">
        <img
          :src="item.iconImg"
          :alt="item.name"
          width="65px"
          height="65px" />
        <div class="m_tb_10">{{ item.label }}</div>
      </div>
    </div>
    <!--  筛选目录  -->
    <div
      class="classify-box flex_col fs_16 bg_fff m_tb_20 p_in_10 br_5 p_lr_20">
      <div
        class="m_tb_10 flex_row_wrap"
        v-for="(parentItem, index) of classifies"
        :key="parentItem.id">
        <div
          class="fs_14 m_r_10 m_tb_5 classify-item pointer"
          :class="{ 'selected': selectClassifies[index] === childItem.id }"
          @click="classifyItem_click(childItem.id, index)"
          v-for="childItem of parentItem"
          :key="childItem.id">
          {{ childItem.name }}
        </div>
      </div>
    </div>
    <div class="resource-box flex_row m_b_20">
      <!--   树形目录   -->
      <div class="tree-box p_in_10 bg_fff br_5">
        <Tree
          :data="leftTreeData"
          @selectLeftOption="leftOption_click" />
      </div>
      <!--   资源列表   -->
      <div
        class="resource-list p_in_10 bg_fff br_5 flex_col"
        v-loading="loading"
        style="justify-content: space-between">
        <div
          v-if="resourceList.length !== 0"
          class="flex_row_wrap">
          <Resource
            class="resource m_b_20"
            v-for="item of resourceList"
            :key="item.id"
            :resourceData="item" />
        </div>
        <el-empty
          v-else
          description="暂无内容"></el-empty>
        <div
          class="flex_row"
          style="justify-content: center">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="current_change"
            :page-size="paginationParams.size"
            :current-page.sync="paginationParams.currentPage"
            :total="paginationParams.total"
            hide-on-single-page></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import banner from 'assets/image/Group 504.png';
import Tree from '../components/resource/Tree';
import Resource from '../components/resource/Resource';
// eslint-disable-next-line no-unused-vars
import {
  getLeftClassifyTree,
  getResourceClassify,
  getResourceClassifyTree,
  getResourceList,
} from '../../../api/resourceCenter';
import { sessionSet } from '../../../utils/local';
import primarySchool from 'assets/image/Group 714.png';
import juniorHighSchool from 'assets/image/Group 715.png';
import highSchool from 'assets/image/Group 716.png';

export default {
  name: 'ProvincialLevel',
  // eslint-disable-next-line vue/no-unused-components
  components: { Tree, Resource },
  data: () => {
    return {
      banner,
      selectClassifies: [],
      classifies: [],
      leftTreeData: [],
      // 请求参数
      resourceParams: {
        // 资源分类id
        classifyId: '',
        // 资源目录id
        contentsId: '',
        // 当前页
        current: 1,
        // 资源ids
        ids: '',
        // 资源标签
        label: '',
        // 资源名称
        name: '',
        // 每页数量
        size: 12,
        // 资源排序 1浏览最多(热门推荐) 2点赞最多 3收藏最多(精品推荐)
        sortDiv: '',
        // 资源类型
        type: '',
        // 1国家资源2省资源3市资源4区资源5空中课堂
        level: 2,
      },
      loading: false,
      fullscreenLoading: false,
      // 分页
      paginationParams: {
        currentPage: 1,
        size: 12,
        total: 0,
      },
      resourceList: [],
      // 选中的目录
      selectedClassify: null,
      // 目录列表
      classifyList: [
        {
          iconImg: primarySchool,
        },
        {
          iconImg: juniorHighSchool,
        },
        {
          iconImg: highSchool,
        },
      ],
    };
  },
  async mounted() {
    this.fullscreenLoading = true;
    await getResourceClassify({ level: 2 }).then(async (res) => {
      // 资源目录
      await getResourceClassifyTree({ id: res.data[0].id }).then((res) => {
        let arr = res.data[0].children;
        arr.forEach((item, index) => {
          this.classifyList[index] = { ...item, ...this.classifyList[index] };
        });

        this.selectedClassify = res.data[0].children[0].id;
        this.processingData(res.data[0].children[0].children, 0);
      });
    });
    this.resourceParams.contentsId = this.selectClassifies.at(-1);

    let id = this.$route.params.id;
    if (id) {
      this.selectedClassify = id;
      this.classify_click(id);
    }

    this.getLeftTree();
    this.getResourceList();
  },
  methods: {
    // 处理筛选目录数据
    processingData(data, i) {
      if (!data) return;
      if (data[0].level > 4) return;
      let arr = [];
      data.forEach((item) => {
        delete item.parentsId;
        delete item.status;
        if (data[0].id === item.id) {
          this.selectClassifies[i] = item.id;
        }
        if (item.children.length !== 0) {
          arr.push({ name: item.label, id: item.id, children: item.children });
        } else {
          arr.push({ name: item.label, id: item.id, children: [] });
        }
      });

      this.classifies[i] = arr;
      i++;

      if (data[0].children.length !== 0) {
        this.processingData(data[0].children, i);
      }

      this.$forceUpdate();
    },
    // 点击筛选目录选项
    classifyItem_click(id, index) {
      this.paginationParams.currentPage = 1;
      this.resourceParams.current = 1;
      this.selectClassifies[index] = id;
      this.classifies = this.classifies.slice(0, index + 1);
      this.classifies[index].forEach((item) => {
        if (item.id === id) {
          if (item.children.length !== 0)
            this.processingData(item.children, index + 1);
        }
      });

      this.resourceParams.contentsId = this.selectClassifies.at(-1);
      this.getLeftTree();
      this.getResourceList();
    },
    // 获取侧边树数据
    getLeftTree() {
      getLeftClassifyTree({ id: this.selectClassifies.at(-1) }).then((res) => {
        this.leftTreeData = res.data;
      });
    },
    // 获取资源列表
    getResourceList() {
      sessionSet('searchParams', this.resourceParams);
      this.loading = true;
      getResourceList(this.resourceParams).then((res) => {
        this.resourceList = res.data.records;
        this.paginationParams.total = res.data.total;
        this.loading = false;
        this.fullscreenLoading = false;
      });
    },
    // 点击左目录树
    leftOption_click(id) {
      this.resourceParams.contentsId = id;
      this.getResourceList();
    },
    // 切换分页
    current_change(nowCurrent) {
      this.resourceParams.current = nowCurrent;
      this.getResourceList();
    },
    // 切换大目录
    classify_click(classifyId) {
      this.resourceParams.contentsId = '';
      this.resourceParams.current = 1;
      this.paginationParams.currentPage = 1;
      this.selectedClassify = classifyId;

      this.classifyList.forEach((item) => {
        if (item.id === classifyId) {
          if (item.children.length !== 0) this.processingData(item.children, 0);
        }
      });

      this.resourceParams.contentsId = this.selectClassifies.at(-1);
      this.getLeftTree();
      this.getResourceList();
    },
  },
};
</script>

<style scoped>
.box {
  width: 1200px;
  margin: auto;
}

.resource-box {
  width: 100%;
  min-height: 400px;
  justify-content: space-between;
}

.tree-box {
  width: 200px;
}

.resource-list {
  width: 980px;
}

.resource {
  margin-right: 12.8px;
}

.resource:nth-child(4n) {
  margin-right: 0;
}

.classify-box {
  min-height: 97px;
}

.classify-item {
  background: rgba(18, 194, 148, 0.05);
  text-align: center;
  border-radius: 30px;
  line-height: 15px;
  padding: 6px 20px;
  color: rgba(0, 0, 0, 0.6);
}

.classify-item:hover {
  background-color: #12c294;
  color: #fff;
}

.selected {
  color: #fff;
  background-color: #12c294;
}

.select-classify {
  background-color: #e3f4f0;
}

.plate-box {
  justify-content: space-between;
}

.plate {
  width: 250px;
  height: 100px;
  justify-content: space-evenly;
  padding: 18px 0;
  line-height: 44px;
}
</style>
